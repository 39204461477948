require('slick-carousel/slick/slick.scss');
require('slick-carousel/slick/slick-theme.scss');
import 'slick-carousel';

/*Left in case a slider is needed*/
require('../scss/front-page.scss');

if (jQuery('.case-slider').length) {
	jQuery('.case-slider').not('.slick-initialized').slick({
		slidesToShow: 3,
		arrows: false,
		responsive: [
			{
			breakpoint: 1024,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 1,
			  }
			},
			{
			breakpoint: 992,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
			  }
			},
			{
			breakpoint: 750,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				dots: true,
				}
			}
		]
	})
}